// React
import { memo, ReactElement, useEffect, useState } from 'react';
// Interfaces
import { SearchMagView } from '@utmb/types/index';
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
// Features
import { SocialIcon } from '@utmb/features/layout/social-share/icons';
// Styles
import styles from './card.module.scss';
import { getLinkForSocialSharing } from '@utmb/utils/social-share';
import { IconsEnum } from '@utmb/types/icons';

export const Card = memo((props: SearchMagView): ReactElement => {
    const { title, summary, date, media, type, slug } = props;

    const { t } = useTranslation();

    const socials = ['facebook', 'twitter', 'email'];

    const [socialToggler, setSocialToggler] = useState(false);
    const [pageUrl, setPageUrl] = useState('');

    useEffect(() => {
        setPageUrl(window.location.href);
    }, []);

    const CARD_WIDTH = 275;

    const classNames = [styles.card, styles.card_link].filter(Boolean).join(' ');
    const socialSharedCSS = [styles.share_panel_closed, socialToggler && styles.share_panel_open].filter(Boolean).join(' ');

    const handleSharePanelClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setSocialToggler(!socialToggler);
    };

    const handleContainerShareClick = (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault();
    const handleShareClick = (e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation();

    return (
        <div className={classNames}>
            <Link href={`/news/${slug}`}>
                <div className={styles.card_thumbnail}>
                    {media && (
                        <>
                            {/* Card Image */}
                            <Image
                                layout="responsive"
                                objectFit="cover"
                                priority
                                image={media}
                                width={CARD_WIDTH}
                                height={media.height && media.width ? Number((CARD_WIDTH * media.height) / media.width) : 150}
                            />
                            {/* Image Overlay */}
                            <div className={styles.card_overlay} />

                            {/* Icon with square */}
                            {type && (
                                <div className={styles.card_icon} style={{ backgroundColor: getColorStyle(ColorTypesEnum.PRIMARY) }}>
                                    <Icon variant={type.toLowerCase() as IconsEnum} color={getColorStyle(ColorTypesEnum.WHITE)} />
                                </div>
                            )}
                        </>
                    )}

                    {/* Share panel */}
                    <div onClick={handleContainerShareClick} className={socialSharedCSS}>
                        <Font.OxaniumRegular mobile="12" desktop="14" className={styles.share_label}>
                            {t('news.share')}
                        </Font.OxaniumRegular>
                        <div className={styles.share_icons}>
                            {socials.map((social) => (
                                <a
                                    key={uuidv4()}
                                    onClick={handleShareClick}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={getLinkForSocialSharing({ social, pageUrl: `${pageUrl}/${slug}` })}
                                >
                                    <SocialIcon variant={social} color={getColorStyle(ColorTypesEnum.PRIMARY)} />
                                </a>
                            ))}
                        </div>
                        <div onClick={handleSharePanelClick} className={styles.share_close_btn}>
                            <Icon variant={IconsEnum.CLOSE} color={getColorStyle(ColorTypesEnum.DEFAULT)} />
                        </div>
                    </div>
                </div>
                <div className={styles.card_content}>
                    <div className={styles.header_content}>
                        <div>
                            {/* Type */}
                            {type && (
                                <Font.OxaniumRegular mobile="14" desktop="14" className={styles.card_date}>
                                    {type}
                                </Font.OxaniumRegular>
                            )}
                            {/* Date */}
                            {date && (
                                <Font.OxaniumRegular mobile="14" desktop="14" className={styles.card_date}>
                                    {date}
                                </Font.OxaniumRegular>
                            )}
                        </div>
                        {/* Share Icon */}
                        <div className={styles.share_panel_icon} onClick={(e) => handleSharePanelClick(e)}>
                            <Icon variant={IconsEnum.SHARE} color={ColorTypesEnum.DEFAULT} />
                        </div>
                    </div>

                    {/* Title */}
                    {title && (
                        <Font.OxaniumRegular mobile="14" desktop="16" className={styles.card_title}>
                            {title}
                        </Font.OxaniumRegular>
                    )}
                    {/* Summary */}
                    {summary && (
                        <Font.FuturaMedium mobile="14" desktop={'18'} className={styles.card_summary}>
                            {summary}
                        </Font.FuturaMedium>
                    )}
                </div>
            </Link>
        </div>
    );
});
