// React
import { ReactElement } from 'react';
// Masonry
import Masonry from 'react-masonry-css';
// Design System
import { Card } from '@utmb/design-system/card/Card';
import SliceListOfMagsView from '@utmb/types/view/SliceListOfMagsView';
// Styles
import styles from './magazine.module.scss';

export const MagazineLatest = ({ articles }: SliceListOfMagsView): ReactElement => {
    const breakpointColumnsObj = {
        default: 3,
        1200: 2,
        768: 1,
    };

    return (
        <section className={`${styles.container} container`}>
            <Masonry breakpointCols={breakpointColumnsObj} className={styles.my_masonry_grid} columnClassName={styles.my_masonry_grid_column}>
                {articles?.mags?.map((mag) => (
                    <Card key={mag.id} {...mag} />
                ))}
            </Masonry>
        </section>
    );
};
